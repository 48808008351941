import { Component,OnInit,Input } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent { 
  items!: MenuItem[]
  @Input() visible: boolean = false;
  ngOnInit() {
    this.items = [
      {
        label: 'Home',
        routerLink: '/home',
        command: () => {
          console.log("Log generated!!!");
        }
      }
      
    ];
  }
  

}
