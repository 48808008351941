export class EnvironmentService {
  ENVIRONMENT_NAME: string;
  PRODUCTION_MODE: boolean;

  API_BASE_PATH: string;

  constructor(environmentService: EnvironmentService) {
    this.API_BASE_PATH  = environmentService.API_BASE_PATH;
    this.PRODUCTION_MODE = environmentService.PRODUCTION_MODE;
    this.ENVIRONMENT_NAME = environmentService.ENVIRONMENT_NAME;
  }
}
