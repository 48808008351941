import { Component } from '@angular/core';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css']
})
export class ShellComponent {
  sidebarVisibility:boolean=false;
  onMenuClick(){
    this.sidebarVisibility = !this.sidebarVisibility;

  }

}
