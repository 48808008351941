import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {enableProdMode} from "@angular/core";
import {EnvironmentService} from "./app/core/services/environment.service";

import(
  /* webpackPrefetch: true */
  /* webpackIgnore: true */
  // @ts-ignore
  './environments/environment.js'
  ).then(module => {

  // @ts-ignore
  const environment: EnvironmentService = new EnvironmentService(module.environment);

  if (module.environment.PRODUCTION_MODE) {
    enableProdMode();
  }


  platformBrowserDynamic([{provide: EnvironmentService, useValue: environment}])
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
