import {Injectable} from '@angular/core';
import {EnvironmentService} from "../../../../core/services/environment.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export interface Hello {
  id: number,
  content: string
}

@Injectable({
  providedIn: 'root'
})
export class HelloService {

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly http: HttpClient
  ) {}

  getData(): Observable<Hello> {
    return this.http.get<Hello>(`${this.environmentService.API_BASE_PATH}/?name=${this.environmentService.ENVIRONMENT_NAME.toUpperCase()} Admin`);
  }
}
