import {Component, OnInit} from '@angular/core';
import {HelloService} from "../services/hello.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  data: string | undefined;

  constructor(private helloService: HelloService) {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.helloService.getData().subscribe(
      (response) => {
        this.data = response.content
      },
      (error) => {
        this.data = 'Error fetching data';
        console.error('Error fetching data:', error);
      }
    );
  }
}
