import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenubarModule} from 'primeng/menubar';
import {ToolbarModule} from 'primeng/toolbar';
import {SidebarModule} from 'primeng/sidebar';
import {MenuModule} from 'primeng/menu';


import {ShellRoutingModule} from './shell-routing.module';
import {SidebarComponent} from './components/shell/sidebar/sidebar.component';
import {HeaderComponent} from './components/shell/header/header.component';
import {HomeComponent} from './components/shell/home/home.component';
import {ShellComponent} from './components/shell/shell.component';

@NgModule({
  declarations: [
    ShellComponent,
    SidebarComponent,
    HeaderComponent,
    HomeComponent,
  ],
  exports:[ShellComponent],
  imports: [
    CommonModule,
    ShellRoutingModule,
    MenubarModule,
    ToolbarModule,
    SidebarModule,
    MenuModule
  ],

})
export class ShellModule { }
