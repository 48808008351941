import { Component } from '@angular/core';
import { ShellModule } from './shell/shell.module';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  title = 'optix-admin-ui';
}
